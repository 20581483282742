import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ApiConnector from './apiConnector';
import { CommonTable, VerticalList } from '../../common';
import { AlertProps, AxiosCall, Searchconnectorapi, upsertapiConnector } from '../../../utils';
import Config from '../../../config';
import { Button } from 'qdm-component-library';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "98%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56,
    // },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    // marginTop: "70px",
    // marginLeft: "30px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
  commanTabbar: {
    display: "flex",
    position: "relative",
    top: "70px",
    marginLeft: "80px"
  }
}));
const styles = {
  outlinedButton: {
      borderColor: "#DEDEDE",
      color: "#2A60BC",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    outlinedDelButton: {
      borderColor: "#FF4D4A",
      color: "#FF4D4A",
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
      whiteSpace:"nowrap",
    },
    modalHeading: {
      fontSize: 16,
      color: "#2A60BC",
      // marginBottom: 10,
    },
}
const ApiConnectorList = (props) => {
    const classes = useStyles();
    const [editValue, seteditValue] = useState();
        const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [isFormOpen, setisFormOpen] = useState({
        isForm: false,
        isEdit: false,
        isView: false,
      }); 
      const [openAlert,setOpenAlert] = useState({
            open:false,
            index:null
          });
     const [apiConnect,setapiConnect]=useState([])
     const [formConnect, setFormConnect] = useState({
            connectortype:'',
            baseurl: '',
            type: '',
            key: '',
            datatype: '',
            source: '',
            value: '',
            authtype: '',
            method: '',
            endpoint: '',
            granttype:"",
            authurl:"",
            tokenurl:"",
            callbackurl:"",
            clientid:"",
            clientsecret:"",
            scope:"",
            state:"",
            codeverifier:"",
            codechallangemethod:"",
            requestin:"",
            username:"",
            password:"",

        });

        //Add List
         const getApiConnectData = async (id, pageDetails, searchVal) => {
            try {
              let payload = {
                db_name:process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                  searchname: searchVal ?? "",
                  page: pageDetails?.page ?? 0,
                  perPage: pageDetails?.perPage ?? 10,
                },
                queryid: Searchconnectorapi
              }
              let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
              let result = []
              if (Array.isArray(Resp)) {
                Resp?.map((v, i) => {
                  let obj = {
                    _id: v?._id,
                    connectortype: v?.connectortype,
                    baseurl: v?.baseurl,
                    // isSavedNew: false,
                    TotalCount: v?.TotalCount,
                  }
                  result.push(obj)
                })
                setapiConnect(result);
              }
            } catch (e) {
              console.error(e)
            }
          }

       //Edit List   
          const getEditConnectData = async (id, pageDetails, searchVal) => {
            try {
              let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                  _id: id
                },
                queryid: Config.getConnect
              }
              let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
              return Resp              
            } catch (e) {
              console.error(e)
            }
          }

           useEffect(() => {
            getApiConnectData();  
            }, [])


            const handleEdit = async (e, row, index) => {
                const  editData = await getEditConnectData(row?._id);
                seteditValue(editData)
                setisFormOpen({
                    ...isFormOpen,
                    isForm: false,
                    isEdit: true,
                    isView: false,
                  });
                }
              

        const handleForm = () => {
            seteditValue([])
            setFormConnect(
              {
                connectortype:'',
                baseurl: '',
                type: '',
                key: '',
                datatype: '',
                source: '',
                value: '',
                authtype: '',
                method: '',
                endpoint: '',    
              }
            )
            setisFormOpen({
              ...isFormOpen,
              isForm: true,
              isEdit: false,
              isView: false,
            });
          };
        
        const handleDelete = async (del) => {
                try {
                  if(del?.open){
                    let deleteData = apiConnect[del?.index];
                    let delPayload = {
                      db_name: process.env.REACT_APP_METADATA_DB_NAME,
                      entity: upsertapiConnector,
                      filter: `${upsertapiConnector}._id == '${deleteData?._id}'`,
                      metadata_dbname: process.env.REACT_APP_MetadataDB_Name
                    }
                    let Resp = await AxiosCall(delPayload,process.env.REACT_APP_SOFT_DELETE)
                    if (Resp) {
                        setOpenAlert({
                          ...openAlert,
                          open:false,
                        index:null
                        })
                        if(Resp?.Code === 201){
                          getApiConnectData()
                        alert?.setSnack({
                          open: true,
                          severity: AlertProps.severity.success,
                          msg: "Deleted Successfully",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        });
                        } else {
                        alert?.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: "Delete Failed",
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        });
                        }
                    } else {
                      alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "please contact admin",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                      });
                    }
                  }
                } catch (e) {
                  console.error(e)
                }
           }

        const handleView = async(e, row, index) => {
            const  editData = await getEditConnectData(row?._id);
            seteditValue(editData)
            setisFormOpen({
              ...isFormOpen,
              isView: true,
              isEdit: false,
              isForm: false,
            });
          }
              
          const handleSearch = (searchVal) => {
            getApiConnectData("", "", searchVal);
          }

          const handlePageChange = async (currentPage, PerPage) => {
            await getApiConnectData("", { page: (currentPage - 1) * PerPage, perPage: PerPage });
          };

  return (
     <div>
      <div className={classes.content}>
      {isFormOpen?.isForm || isFormOpen?.isView || isFormOpen?.isEdit ?
         <ApiConnector
            formConnect={formConnect}
            setFormConnect={setFormConnect}
            editValue={editValue}
            getApiConnectData={getApiConnectData}
            setisFormOpen={setisFormOpen}
            isFormOpen={isFormOpen}
          />:
          <VerticalList
            parent_id={"api_manamement"}
            addFunc={handleForm}
            id={"apiconnector"}
            placeholder={"search url"}
            onSearchChange={handleSearch}
            hideSearchBar={false}
            hideFilter={true}          
            TabList={[
              {
                name: "API Connector",
                component: (
                  <CommonTable
                    parent_id={"api_manamement"}
                    rightAlign={["View", "Action"]}
                    handleEdit={(e, data, index) => {
                      handleEdit(e, data, index);
                    }}

                    handleView={(e, data, index) => {
                      handleView(e,data,index);
                    }}
                    
                    handleDelete={(e,v,i)=>setOpenAlert({
                      open:true,
                      index:i
                    })}
                    Header={[
                      { label: "S.No", showSort: false, showFilter: false },
                      { label: "CONNECTOR TYPE", showSort: false, showFilter: false },
                      { label: "BASE URL", showSort: false, showFilter: false },
                      { label: "Action", showSort: false, showFilter: false },
                      { label: "View", showSort: false, showFilter: false },
                    ]}
                    dataList={apiConnect ?? []}
                    tableData={[
                      { type: ["INCRIMENT"], name: "1" },
                      { type: ["TEXT"], name: "connectortype" },
                      { type: ["TEXT"], name: "baseurl" },
                      { type: ["EDIT", "DELETE"], name: "", align: "right" },
                      { type: ["VIEW",], name: "", align: "right" },
                    ]}
                    handlepageChange={(currentPage, PerPage) =>
                      handlePageChange(currentPage, PerPage)
                    }
                    TableCount={ApiConnectorList?.[0]?.TotalCount}
                    incrementCount={formConnect?.page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                  />
                ),
              },
            ]}
          />
          }
          </div>
          {openAlert?.open && (
        <Dialog
        fullScreen={fullScreen}
        open={openAlert?.open}
        onClose={()=>setOpenAlert({
          ...openAlert,
          open:false
        })}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle style={styles.modalHeading} id="alert-dialog-title">{"Are you Sure Want to Delete ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding:"15px"}}>
          <Button onClick={()=>setOpenAlert({
            ...openAlert,
            open:false
          })} color="primary"
          style={styles.outlinedButton}
          variant="contained"
          >
            No
          </Button>
          <Button onClick={()=>handleDelete(openAlert)} color="primary"
          style={styles.outlinedDelButton}
          variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      )}
    </div>
  )
}

export default ApiConnectorList