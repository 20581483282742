import React from "react";
import { CommonTable, GenerateForm } from "../../../components"
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { withAllContexts } from "../../../HOCs";
import { AlertProps, codeablemasterentity, gettabledata, updatequeryid, codingmaster } from "../../../utils";
import { CircularProgress } from "@material-ui/core";

// const local_loading = true;

const get_component_type = (name) => {
  let type = name?.toLocaleLowerCase()?.trim();
  switch (type) {
    case "string":
    case "text":
    case "text box":
      return "text"
    case "numeric":
      return "text"
    case "boolean":
    case "switch":
      return "switch"
    case "checkbox":
      return "checkbox"
    case "date":
      return "date"
    case "select":
      return "select"
    case "single select":
      return "select";
    default:
      return "text"
  }
}
class TableFormData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      masterData: [],
      edit_data: null,
      edit_index: null,
      perPage: 10,
      page: 0,
      type: "",
      search: "",
      code_sort: true,
      short_sort: true,
      long_sort: true,
      vist_type: true,
      grade_sort: true,
    };
    this.local_loading = true;
    // this.saveForm = this.saveForm.bind(this)
    this.handleEdit = this.handleEdit.bind(this);
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  // handlepageChange = async (currentPage, PerPage) => {
  //   this.setState({
  //     page: (currentPage - 1) * PerPage,
  //     perPage: PerPage,
  //   })
  //   await this.props.GET_GENERAL_MASTER_TYPE({ type: this?.props?.parent_id, page: (currentPage - 1) * PerPage, perPage: PerPage });
  // };

  handlePageChange = async (currentPage, perPage) => {
    const pageOffset = (currentPage - 1) * perPage;
    await this.setState({
      page: pageOffset,
      perPage: perPage,
    });
    await this.getTableDatas(this.props?.parent_id, pageOffset, perPage);
  };
  

  getTableDatas = async (type, page, perpage) => {
    // await this.props.GET_GENERAL_MASTER_TYPE({ type, page: this.state.page, perPage: this.state.perPage })
    // this.setState({
    //   ...this.state,
    //   type: type,
    // })
    const axios = require("axios");
    let data = JSON.stringify({
      db_name: process.env.REACT_APP_DB,
      filter: {
        type: type,
        page: page || 0,
        perpage: perpage || 10,
      },
      queryid: gettabledata,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        this.setState({
          ...this.state,
          tableData: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    if (this?.props?.parent_id) {
      this.getTableDatas(this.props.parent_id, this.state.page, this.state.perPage);
    }
    if (this.props?.search) {
      this.setState({
        ...this.state,
        search: this.props?.search,
      });
    }
    if (this?.props?.options) {
      this.setState({ ...this.state, options: this?.props?.options });
    } else {
      let masters = [];
      this.props?.data?.additionalcolumns?.map((val) => {
        if (
          get_component_type(val?.columntype?.display).includes("select") &&
          val?.columnmaster?.name
        ) {
          masters.push(val?.columnmaster?.name);
        }
      });
      if (masters?.length > 0) {
        this.getColunmMaster(masters);
      }
    }
  }

  getColunmMaster = async (list) => {
    let options = await this.props.COLUMN_MASTERS(list);
    if (options?.payload?.data) {
      this.setState({ ...this.state, options: options?.payload?.data });
    }
  };

  handleEdit = (e, values, index) => {
    let data = {
      code: values?.code,
      display: values?.display,
      shortdesc: values?.shortdesc,
      Type: values?.Type,
      status: true,
     _id: values?._id,
      id: values?.id,
    };
    this.setState({
      ...this.state,
      openEditFrom: true,
      edit_data: data,
      // edit_data: { ...values, ...values?.coding?.[0]?.gmconfigvalues },
      edit_index: index,
    });
  };

  handleOpenForm = (val) => {
    const { changeState } = this.props;
    changeState("openIndex", 0);
    this.setState({
      openEditFrom: true,
      edit_data: { ...val },
    });
  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: null,
    });
  };

  // saveFn = async (val) => {
  //   console.log("val", val);
  //   // let state = this.state;
  //   if (this.state.edit_data === null) {
  //     // state.masterData.push(val)
  //     let new_data = {
  //       type: this.props.parent_id,
  //       ...val
  //     }
  //     let additionalcol = Object.keys(new_data).filter(v => v !== "type" && v !== "code" && v !== "display" && v !== "shortdesc");
  //     let list = {}
  //     additionalcol.map(val => {
  //       list[val] = new_data[val];
  //     })
  //     new_data["addition"] = list;
  //     let addnew = await this.props.ADD_NEW_GENERAL_MASTER_TYPE(new_data)
  //     console.log(addnew, "addnew");
  //     if (addnew?.payload?.data?.error) {
  //       let type = addnew?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique") ? "unique" : "error"
  //       this.props.alert.setSnack(type);
  //       return false;
  //     } else {
  //       this.props.alert.setSnack("success");
  //       await this.getTableDatas(this.props.parent_id)
  //     }

  //   } else {
  //     let edit_data = {
  //       type: this.state?.edit_data?.Type ?? "",
  //       _key: this?.state?.edit_data?.coding?.[0]?._key,
  //       ...val
  //     }
  //     let additionalcol = Object.keys(edit_data).filter(v => v !== "type" && v !== "code" && v !== "display" && v !== "shortdesc");
  //     let list = {}
  //     additionalcol.map(val => {
  //       list[val] = edit_data[val];
  //     })
  //     edit_data["addition"] = list;
  //     let editdata = await this.props.UPDATE_GENERAL_MASTER_TYPE(edit_data)
  //     if (editdata?.payload?.data?.error) {
  //       let type = editdata?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique") ? "unique" : "error"
  //       this.props.alert.setSnack(type);
  //       return false;
  //     } else {
  //       this.props.alert.setSnack("update");
  //       await this.getTableDatas(this.props.parent_id)
  //     }
  //   }
  //   this.handleCloseForm()
  // };

  additionalCol = (dt) => {
    // if (dt?.additionalcolumns?.length > 0) {
    //   let list = [];
    //   dt?.additionalcolumns?.map((cal) => {
    //     list.push(cal.columnname);
    //   });
    //   let arr = [];
    //   if (list.includes("Visit Type Indicator")) {
    //     list.pop("Visit Type Indicator");
    //     // alert(JSON.stringify(list))
    //     arr = [
    //       "S.No",
    //       {
    //         label: "Code",
    //         showSort: true,
    //         sortValue: this.state.code_sort,
    //         onclick: (val) =>
    //           this.handleSort(val, "code_sort", !this.state.code_sort),
    //       },
    //       "Short Description",
    //       "Long Description",
    //       {
    //         label: "Visit Type Indicator",
    //         showSort: true,
    //         sortValue: this.state.vist_type,
    //         onclick: (val) =>
    //           this.handleSort(val, "vist_type", !this.state.vist_type),
    //       },
    //       // ...list,
    //       "Status",
    //       "Action",
    //     ];
    //   } else if (list?.includes("Grade")) {
    //     let temp = list?.filter((val) => val !== "Grade");
    //     // alert(JSON.stringify(temp))
    //     arr = [
    //       "S.No",
    //       {
    //         label: "Code",
    //         showSort: true,
    //         sortValue: this.state.code_sort,
    //         onclick: (val) =>
    //           this.handleSort(val, "code_sort", !this.state.code_sort),
    //       },
    //       "Short Description",
    //       "Long Description",
    //       {
    //         label: "Grade",
    //         showSort: true,
    //         sortValue: this.state.grade_sort,
    //         onclick: (val) =>
    //           this.handleSort(val, "grade_sort", !this.state.grade_sort),
    //       },
    //       ...temp,
    //       "Status",
    //       "Action",
    //     ];
    //   } else {
    //     arr = [
    //       "S.No",
    //       {
    //         label: "Code",
    //         showSort: true,
    //         sortValue: this.state.code_sort,
    //         onclick: (val) =>
    //           this.handleSort(val, "code_sort", !this.state.code_sort),
    //       },
    //       "Short Description",
    //       "Long Description",
    //       // !list.includes("Visit Type Indicator") ? '' : ...list,
    //       ...list,
    //       "Status",
    //       "Action",
    //     ];
    //   }
    //    return arr;
    // } else {

      return [
        { label: "S.No", showSort: false, showFilter: false },
        {
          label: "Code",
          showSort: true,
          sortValue: this.state.code_sort,
          onclick: (val) =>
            this.handleSort(val, "code_sort", !this.state.code_sort),
          showFilter: false,
        },
        {
          label: "Short Description",
          showSort: true,
          sortValue: this.state.short_sort,
          onclick: (val) =>
            this.handleSort(val, "short_sort", !this.state.short_sort),
          showFilter: false,
        },
        {
          label: "Long Description",
          showSort: true,
          sortValue: this.state.long_sort,
          onclick: (val) =>
            this.handleSort(val, "long_sort", !this.state.long_sort),
          showFilter: false,
        },
        { label: "Status", showSort: false, showFilter: false },
        { label: "Action", showSort: false, showFilter: false },
      ];
    // }
  };

  additionalState = (state_name) => {
    // if (state_name?.additionalcolumns?.length > 0) {
    //   let list_state = [];
    //   state_name?.additionalcolumns?.map((val) => {
    //     list_state.push({ type: ["TEXT"], name: val?.columnname });
    //   });
    //   return [
    //     { type: ["INCRIMENT"], name: "" },
    //     { type: ["TEXT"], name: "code" },
    //     { type: ["TEXT"], name: "shortdesc" },
    //     { type: ["TEXT"], name: "display" },
    //     ...list_state,
    //     { type: ["CHECKBOX"], name: "status" },
    //     { type: ["EDIT"], name: "" },
    //   ];
    // } else {
      return [
        { type: ["INCRIMENT"], name: "" },
        { type: ["TEXT"], name: "code" },
        { type: ["TEXT"], name: "shortdesc" },
        { type: ["TEXT"], name: "display" },
        { type: ["CHECKBOX"], name: "status" },
        { type: ["EDIT"], name: "" },
      ];
    // }
  };

  generate_auto_form = (data, disableList, isNew) => {
    // if (data?.additionalcolumns?.length > 0) {
    //   let list = [];
    //   data?.additionalcolumns?.map((val) => {
    //     list.push({
    //       // componet_type: val?.columntype?.display??"text",
    //       componet_type: get_component_type(val?.columntype?.display),
    //       // options: get_component_type(val?.columntype?.display) === "select" ? this.props?.dropDownData_ ?? [] : [],
    //       options: get_component_type(val?.columntype?.display).includes(
    //         "select"
    //       )
    //         ? this.state?.options?.[
    //             this.props?.options ? val?.columnname : val?.columnmaster?.name
    //           ] ?? []
    //         : [],
    //       label: val?.columnname,
    //       state_name: val?.columnname,
    //       value: this.state?.edit_data?.[val?.columnname] ?? "",
    //       required: val?.ismandatory ?? false,
    //       maxlength: val?.columnsize,
    //     });
    //   });

    //   return [
    //     {
    //       componet_type: "text",
    //       label: "Code",
    //       disabled: Boolean(
    //         !isNew && (disableList?.indexOf("Code") > -1 ?? false)
    //       ),
    //       isUpperCase: Boolean(
    //         this?.props?.uppercaseList?.indexOf("Code") > -1 ?? false
    //       ),
    //       state_name: "code",
    //       value: this.state?.edit_data?.["code"] ?? "",
    //       required: true,
    //       maxlength: this.getCodeLength(data.name),
    //     },
    //     {
    //       componet_type: "text",
    //       label: "Short Description",
    //       state_name: "shortdesc",
    //       value: this.state?.edit_data?.["shortdesc"] ?? "",
    //       required: true,
    //       maxlength: 60,
    //     },
    //     {
    //       componet_type: "text",
    //       label: "Long Description",
    //       state_name: "display",
    //       value: this.state?.edit_data?.["display"] ?? "",
    //       required: true,
    //       maxlength: 100,
    //     },
    //     ...list,
    //   ];
    // } else {
      return [
        {
          componet_type: "text",
          label: "Code",
          disabled: Boolean(
            !isNew && (disableList?.indexOf("Code") > -1 ?? false)
          ),
          isUpperCase: Boolean(
            this?.props?.uppercaseList?.indexOf("Code") > -1 ?? false
          ),
          state_name: "code",
          value: this.state?.edit_data?.["code"] ?? "",
          required: true,
          maxlength: 20,
        },
        {
          componet_type: "text",
          label: "Short Description",
          state_name: "shortdesc",
          value: this.state?.edit_data?.["shortdesc"] ?? "",
          required: true,
          maxlength: 60,
        },
        {
          componet_type: "text",
          label: "Long Description",
          state_name: "display",
          value: this.state?.edit_data?.["display"] ?? "",
          required: true,
          maxlength: 100,
        },
      ];
    // }
  };

  // statusChange = async (e,val) => {
  //   const axios = require("axios");
  //   try {
  //     const tableData = this.state.tableData?.[0];
  //     const newStatus = e.target.checked;
  //     const codingArray = tableData?.coding.map((item) => {
  //       if(val?._id === item?._id){
  //         item.status = newStatus
  //       } 
  //       let obj = { ...item}; 
  //       delete obj.TotalCount;                   
  //       return obj;
  //     }); 
  //     let data = JSON.stringify([
  //       {
  //         db_name: process.env.REACT_APP_DB,
  //         entity: codeablemasterentity,
  //         is_metadata: true,
  //         metadataId: process.env.REACT_APP_METADATAID,
  //         metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
  //         filter: {
  //           _key: tableData?._key,
  //         },
  //         doc: {
  //           Type: tableData?.Type,
  //           _id: tableData?._id,
  //           _key: tableData?._key,
  //           _rev: tableData?._rev,
  //           activestatus: true,
  //           coding: codingArray,
  //           createdby: "",
  //           createddate: tableData?.createddate,
  //           id: tableData?.id,
  //           status: tableData?.status,
  //           text: tableData?.text,
  //           updatedate: tableData?.updatedate,
  //           updatedby: "",
  //         },
  //       },
  //     ]);
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: process.env.REACT_APP_UPSERT_DOC,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     const response = await axios.request(config);
  //     if(response?.data?.Code === 201){
  //       this.props.alert.setSnack("update");
  //     } else {
  //       this.props.alert.setSnack("error");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   await this.getTableDatas(this.props.parent_id, this.state.page, this.state.perPage);
  //   // this.local_loading = false;
  //   // let data = {
  //   //   status: e?.target?.checked ?? false,
  //   //   coding_key: values?._key ?? null,
  //   //   codeable_concept_key: values?._key ?? null,
  //   // };
  //   // let dat = await this.props.UPDATE_GENERAL_MASTER_STATUS(data);
  //   // if (dat?.payload?.data?.Code === 201) {
  //   //   this.props.alert.setSnack("update");
  //   // } else {
  //   //   this.props.alert.setSnack("error");
  //   // }
  //   // await this.getTableDatas(this.props.parent_id);
  //   // this.local_loading = true;
  // };
  statusChange = async (e,val) => {
    const updatedStatus = e.target.checked;
    const filter = { _id: val?._id };
    try {
      let res = await this.saveForm(val, updatedStatus, filter);
      if(res?.data?.Code === 201){
        alert.setSnack("update");
      } else if(res?.data?.Code === 200){
        alert.setSnack("error");
      }
    } catch (error) {}
  
  };
  getCodeLength = (name) => {
    // Please add the name of the master inside the list incase you want to change the maximum length of the "Code"
    let list = ["VISITTYPE"];
    const isName = list.find((val) => val === name);
    if (name === isName) {
      return 10;
    } else {
      return 20;
    }
  };

  handleSort = async (val, key, direction) => {
    await this.props.GET_GENERAL_MASTER_TYPE({
      type: this.state.type,
      search: this.state.search?.trim(),
      page: this.state.page,
      perPage: this.state.perPage,
      sort: val,
      direction: direction,
    });
    this.setState({
      ...this.state,
      [key]: !this.state?.[key],
    });
  };
  
  updatequery = async (type, value) => {
    const axios = require("axios");
    let data = JSON.stringify({
      db_name:process.env.REACT_APP_DB,
      filter: {
        codeableconcepid: type,
        codingmasterid: value,
      },
      queryid: updatequeryid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_READ_QDM_QUERY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  saveForm = async (val,status, filter) => {
    const axios = require("axios");
    try {
      const tableData = this.state.tableData?.[0];
      const finalFilter = filter?._id
      ? { _id: filter._id }
      : this.state.edit_data
      ? { _id: this.state.edit_data._id }
      : {};
      let data = JSON.stringify([
        {
          db_name: process.env.REACT_APP_DB,
          entity: codingmaster,
          is_metadata: true,
          metadataId: process.env.REACT_APP_METADATAID,
          metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
          filter: finalFilter,
          doc: {
            system: "",
            version: "",
            code: val?.code,
            display: val?.display,
            userSelected: true,
            id: 0,
            Type: tableData?.Type,
            shortdesc: val?.shortdesc,
            status: status ?? true, 
            tenantid: "",
            facilityid: "",
            privilage: "",
            description: "",
            strval: "",
          },
        },
      ]);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_UPSERT_DOC,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      this.handleCloseForm();
      if (response?.data?.Result?.[0]?.properties?.doc?._id) {
        await this.updatequery(
          tableData?._id,
          response?.data?.Result?.[0]?.properties?.doc?._id
        );
      }
      await this.getTableDatas(
        this.props.parent_id,
        this.state.page,
        this.state.perPage
      );
      if (response?.data?.Code === 201) {
        this.props.alert.setSnack("success");
      } else if (response?.data?.Code === 200) {
        this.props.alert.setSnack("update");
      } else if (response?.data?.Code === 1111) {
        this.props.alert.setSnack("error", response?.data?.validation_error?.[0]?.Errormsg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { open, openIndex, parent_id, parent_id_ } = this.props;
    console.log("table_data", this.props.data);
    return (
      <React.Fragment>
        {this?.props?.table_list?.loading && this.local_loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "64.5vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <CommonTable
            handleCheckBox={this.statusChange}
            style={{ height: "calc(100vh - 243px)" }}
            parent_id={parent_id}
            handleEdit={this.handleEdit}
            // Header={[
            //   "S.No",
            //   "Code",
            //   "Short Description",
            //   "Long Description",
            //   "Status",
            //   "Action",
            // ]}
            Header={this.additionalCol(this.props.data)}
            // dataList={this?.props?.table_list?.data ?? []}
            dataList={this.state.tableData?.[0]?.coding}
            // tableData={[
            //   { type: ["INCRIMENT"], name: "" },
            //   { type: ["TEXT"], name: "code" },
            //   { type: ["TEXT"], name: "shortdesc" },
            //   { type: ["TEXT"], name: "display" },
            //   { type: ["CHECKBOX"], name: "status" },
            //   { type: ["EDIT"], name: "" },
            // ]}
            tableData={this.additionalState(this.props.data)}
            handlepageChange={(currentPage, PerPage) =>
              this.handlePageChange(currentPage, PerPage)
            }
            // TableCount={this?.props?.table_list?.data?.length}
            TableCount={this.state.tableData?.[0]?.coding?.[0]?.TotalCount}
            incrementCount={this.state.page}
            showPagination={true}
            rowsPerPageOptions={[
              { label: "10 Rows", value: 10 },
              { label: "50 Rows", value: 50 },
              { label: "100 Rows", value: 100 },
            ]}
          />
        )}
        {/* {JSON.stringify(this?.props?.table_list?.data)} */}
        <GenerateForm
          parent_id={parent_id}
          nocancel
          open={(open || this.state.openEditFrom) && openIndex === openIndex}
          handlecancel={() => this.handleCloseForm()}
          header={
            this.state.edit_data === null
              ? "Add new " + parent_id
              : "Edit " + parent_id
          }
          json={this.generate_auto_form(
            this.props.data,
            this.props.disableList,
            Boolean(this.state.edit_data === null)
          )}
          //   loading={this.props.upsertOrderCategory?.loading}
          save={(val) => this.saveForm(val)}
          loading={this.props.update_data?.loading}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  // table_list: state?.generalMasterSlice?.get_general_master_type,
  update_data: state?.generalMasterSlice?.add_new_general_type
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(TableFormData));