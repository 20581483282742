/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2020-12-01
 * @desc All the environment variables are taken from process.env and exported 
 * via Config variable.
 */

import dotenv from "dotenv";
dotenv.config();

let Config = {};
Config.metadataid = process.env.REACT_APP_METADATAID;
Config.databaseName = process.env.REACT_APP_DATABASE_NAME;
Config.userEntity = process.env.REACT_APP_USERS_ENTITY;
Config.personEntity = process.env.REACT_APP_PERSON_ENTITY;
Config.roleEntity = process.env.REACT_APP_ROLES_ENTITY;
Config.permissionEntity = process.env.REACT_APP_PERMISSIONS_ENTITY;
Config.repositoryEntity = process.env.REACT_APP_REPOSITORY_ENTITY;
Config.practitionerEntity = process.env.REACT_APP_PRACTITIONER_ENTITY;
Config.practitionerRoleEntity = process.env.REACT_APP_PRACTITIONERROLE_ENTITY;
Config.permissionRoleMappingEntity = process.env.REACT_APP_PERMISSION_ROLE_MAPPING_ENTITY;
Config.realm = process.env.REACT_APP_REALM;
Config.secretKey  = process.env.REACT_APP_CLIENT_ID;
Config.clientName = process.env.REACT_APP_CLIENT_NAME;
Config.keylock_url = process.env.REACT_APP_KEY_CLOCK_API;
Config.api_url = process.env.REACT_APP_ARANGO_API;
Config.graphql = process.env.REACT_APP_GQL_ENDPOINT;
Config.socket = process.env.REACT_APP_SOCKET;
Config.IDM = process.env.REACT_APP_TOKEN_URL; 
// Config.uploadUrl = `${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}:${Config.Image_upload_port}/${Config.db_name}/${Config.configration_number}`;
// file upload 
Config.db_name = process.env.REACT_APP_DATABASE_NAME;
Config.Image_upload_protocal = process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL ? process.env.REACT_APP_IMAGE_UPLOAD_PROTOCAL : "https";
Config.Image_upload_host = process.env.REACT_APP_IMAGE_UPLOAD_HOST ? process.env.REACT_APP_IMAGE_UPLOAD_HOST : "fileupload.dev.ainqaplatform.in";
Config.Image_upload_port = process.env.REACT_APP_IMAGE_UPLOAD_PORT ? process.env.REACT_APP_IMAGE_UPLOAD_PORT : 443;
Config.configration_number = process.env.REACT_APP_CONFIG_NO ? process.env.REACT_APP_CONFIG_NO : 11;
Config.uploadUrl = `${Config.Image_upload_protocal}://${Config.Image_upload_host}:${Config.Image_upload_port}/${Config.db_name}/${Config.configration_number}`;
Config.downloadUrl = `${Config.Image_upload_protocal}://${Config.Image_upload_host}:${Config.Image_upload_port}/${Config.db_name}/`;
Config.codabaleConceptMaster = process.env.REACT_APP_CODABALECONCEPTMASTER;

Config.ruleBuilderUiUrl = process.env.REACT_APP_RULEBUILDERURL ? process.env.REACT_APP_RULEBUILDERURL : "http://164.52.212.67:7062"
Config.TokenApikey = process.env.REACT_APP_LOGIN_API_KEY;

Config.generateSequenceApi = `${process.env.REACT_APP_ARANGO_API}/api/generate_sequence`
Config.__addTimeTable__ = `${process.env.REACT_APP_APPOINTMENT_API}/api/addTimeTable`;
Config.__deleteSlot__ = `${process.env.REACT_APP_APPOINTMENT_API}/api/deleteTimeTable`;
Config.__PractitionerLoginURL__ = `${process.env.REACT_APP_TOKEN_URL}/PractitionerLogin`;

Config.factsDefinationEntityName = "factsdefinition"
Config.notificationConfig = "Notification_Configuration"
Config.ResourcebyApptTypeEntity = "ResourcebyApptType"
Config.ResourceTimeTableEntity = "ResourceTimeTable"
Config.paratypesentity ="ParamsMapping"

Config.HolidaydtlsEntityName="Holidaydtls"

Config.getAlertSchemaQueryId = "78a8e517-c641-434e-8eff-1a045120df53"
Config.getSpcInsructQueryId = "be26a8ae-2181-4a08-bcbc-d0b07632fe84"
Config.getGenInsructQueryId = "d9fef26c-9ac6-4921-9cce-b776c185f529"
Config.getResourceRoleQueryId = "133cbcbf-90b2-4739-9d1e-b89de4189d66"
Config.getResourseTableList = "19eabf98-fcd6-487a-a05a-826a4b4712b1"
Config.getResourceNameQueryId = "43954ce2-d68a-4f1d-9bac-68469c3f1902"
Config.getTimeTableReadQueryID = "69f7dac3-4333-46b2-94e8-83ed99354516"
Config.getHolidayTimeTableQueryID = "5c8de03b-85d5-4d8b-9929-74e95d21d99f"
Config.getWorkQueueQueryID = "7a615b64-fceb-473e-b6ee-05f968bf9738"
Config.getOrgTreeReadQueryId = "0d4f06c8-d677-4352-9367-b1a4295061c3"
Config.getOrgTreeQueryId = "2bee413b-87d5-40a4-9695-72be382cb3da"
Config.getUserRoles = "9388670c-3da1-49cf-9a39-d2fe981e3414"
Config.getAppTypeStatusUpdateQueryID = "cc43cf99-ee04-4c3a-8822-3ea65a84c257"
// Config.getServiceCodeQueryID = "0514175d-71e8-4ee1-be77-b9122631f646"
Config.getServiceCodeQueryID = "c80f1ced-6121-4863-8acb-ec984472c4be"

Config.getApplicableAppTypeDropDown="1bfa41eb-2008-4a8c-835b-c7662dd47b9e"
Config.getFunctionList="813b7a32-e51e-44b6-98c1-be0c86101087"
Config.getapiList="e7a04ec4-7fbb-4135-ac46-1fa53fdc8ad8"
Config.geteditApi="62f9c44c-dc2b-4b2d-9557-191b83772309"
Config.getApi="12c07da1-b8c7-4b5c-8664-8ce49af4252e"
Config.getConnect="8a36e52a-4f91-4482-bff7-9c7f7cd503e0"



export default Config;
