import React, { useContext, useEffect, useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Card,
  Button,
  Popover,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { SelectBox } from "../../../components";
import { tr } from "date-fns/locale";
import { findAllByDisplayValue } from "@testing-library/react";
import { AlertContext } from "../../../contexts";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { AlertProps, AxiosCall } from "../../../utils";
import { useHistory } from "react-router";
import Config from "../../../config";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 4,
    color: "grey",
  },
  formParent: {
    paddingRight: 10,
    marginLeft: 0,
  },
}));

function CreateRouteDetails(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const {
    setFinalValue,
    finalValue,
    TableReadData,
  } = props;
  const classes = useStyles();
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editshow, seteditshow] = useState(false);
  const [valueshow, setvalueshow] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [route, setRoute] = useState({
    roles: "",
    routeurl: "",
  });
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentEditIndex(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setRoute({
        roles: "",
        routeurl: ""
    });
  };

  React.useEffect(() => {
    dispatch(actions.RES_APPOINTMENT_APPROUTEURL_LOAD())
  }, []);

  const routedata = useSelector((state) => state.resourceByAppointmentTypesSlice);

  const handleChangelist = async (key, value) => {
    setRoute({
      ...route,
      [key]: value,
    });
  };

  const handleEditvalue = (v, i) => {
    seteditshow(true);
    setCurrentEditIndex(i);
    setRoute({
      roles: v?.roles,
      routeurl: v?.routeurl,

    });
    // getServiceCode(v?.servicetype?._id,v?.servicecategory?._id);
  };

  const deleteResources = (index) => {
    setFinalValue((prevFinalValue) => {
      const updatedRouteDetails = [...prevFinalValue.routedetails];
      updatedRouteDetails.splice(index, 1);
      return {
        ...prevFinalValue,
        routedetails: updatedRouteDetails,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };


//   const deleteResources = (index) => {
//     setFinalValue((prevFinalValue) => {
//       const updatedResourceDefinition = [...prevFinalValue.resourcedefinition];
//       updatedResourceDefinition.splice(index, 1);
//       return {
//         ...prevFinalValue,
//         resourcedefinition: updatedResourceDefinition,
//       };
//     });

//     setvalueshow((prevValues) => {
//       const updatedValues = [...prevValues];
//       updatedValues.splice(index, 1);
//       return updatedValues;
//     });
//   };

  const handlesubmit = () => {
    if (currentEditIndex !== null) {
      if(  route?.roles?.label && 
        route?.routeurl?.label ){
          let updatedRouteDetails = finalValue.routedetails 
          updatedRouteDetails[currentEditIndex] = route;
          setFinalValue({
            ...finalValue,
            routedetails:updatedRouteDetails
          })
          // setFinalValue((prevFinalValue) => {
          //   const updatedServiceDefinition = [
          //     ...prevFinalValue.servicedefinition,
          //   ];
          //   updatedServiceDefinition[currentEditIndex] = resourse; // Update at the same index
          //   return {
          //     ...prevFinalValue,
          //     servicedefinition: updatedServiceDefinition,
          //   };
          // });
          const updatedValues = [...valueshow];
          updatedValues[currentEditIndex] = route;
          setvalueshow(updatedValues);
          setRoute({
           roles: "",
            routeurl: "",
          });
          setCurrentEditIndex(null);
          setAnchorEl(null);
          seteditshow(false);
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      if (
        route?.roles?.label &&
        route?.routeurl?.label
      ) {
        const updatedValues = [...valueshow, route];
        setvalueshow(updatedValues);
        setError(false)
        setFinalValue({
          ...finalValue,
          routedetails:updatedValues
        })
      } else {
        setError(true)
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      setRoute({
        roles: "",
        routeurl: "",
        
      });
      setAnchorEl(null);
    }
  };



  useEffect(() => {
    const routeData = TableReadData[0];
    if (history?.location?.state?.IsEdit && routeData ) {
      let routedetailsdataarry = [];
      routeData?.routedetails?.map((val) => {
        const mappedData = {
          roles: {
            _id: val?.roles?._id || "",
            label: val?.roles?.display,
            Value: val?.roles?.display,
          },
          routeurl: {
            _id: val?.routeurl?._id,
            label: val?.routeurl?.display,
            Value: val?.routeurl?.display,
          },

        };
        routedetailsdataarry.push(mappedData);
      });
      setvalueshow(routedetailsdataarry);
    }
  }, [TableReadData]);


  return (
    <>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} style={{ display: "flex" }}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                ROUTE DETAILS
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  //   className={classes.newRes}
                  // color="primary"
                  onClick={handleClick}
                  style={{
                    marginTop: "-6px",
                    color: "#0071F2",
                    fontFamily: "poppinsemibold",
                  }}
                  button
                  disableTouchRipple
                  id={"resource_definition_card_Id"}
                >
                  + Add New Route
                </Button>
                <div style={{ marginBottom: "8px" }}>
                  {/* <img
                  src={expandarrow}
                  alt="icon"
                  style={{
                    transform: show ? "rotate(180deg)" : "rotate(359deg)",
                  }}
                  /> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {valueshow?.length > 0 &&
            valueshow?.map((v, i) => {
              return (
                <Grid
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    // marginBottom: 8,
                    backgroundColor: "#F9F9F9",
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: "4%",
                          display: "flex",
                          padding: 10,
                        }}
                      >
                        {/* <div>{v.type.label}</div>
                        <div>
                          {v.role ? v.role.label : v.role ? v.role : " "}
                          {""}
                          <div className={classes.dot} />

                          {v.department.map((v) => v.label).join()}

                          {""}
                          <div className={classes.dot} />

                          {v.namelist.map((v) => v.label).join()}

                          {""}
                        </div> */}
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.roles?.label}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.routeurl?.label}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon
                          onClick={(e) => {
                            handleClick(e);
                            handleEditvalue(v, i);
                          }}
                          style={{ color: "#0071F2", fontSize: 18 }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        onClick={() => deleteResources(i)}
                      >
                        <DeleteIcon
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Card>
      </Card>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 18, width: 1000 }}>
          <Grid container direction="row">
            <Grid item lg={11} md={11} xl={11}>
              <Typography
                style={{
                  color: "#000000",
                  fontSize: 14,
                  marginBottom: 8,
                  marginTop: 0,
                  fontWeight: "bold",
                }}
              >
                Add Route
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <CloseIcon
                style={{
                  marginTop: "-18px",
                  cursor: "pointer",
                  marginLeft: "36px",
                }}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} lg={12}>
                <Grid
                   item lg={6} md={6}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Roles
                    <span style={{ color: "red" }}>
                        *
                    </span>
                    </Typography>

                    <SelectBox
                    parent_id={"serviceDefinition-select"}
                    // multi={true}
                    value={route?.roles ?? []}
                    // onchange={(e, value) => onClick("servicetype", value)}
                    onchange={(e, v) =>
                      handleChangelist("roles",v)
                    }
                    list={
                       finalValue?.userroles ?? []
                    }
                    
                    error={error && !route?.roles?.label}
                    />
                </Grid>
                <Grid
                    item lg={6} md={6}
                >
                    <Typography
                   style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Route URL
                    <span
                        id={`service category-star-span`}
                        style={{ color: "red" }}
                    >
                        *
                    </span>
                    </Typography>

                    <SelectBox
                    parent_id={"serviceDefinition-select"}
                    // multi={true}
                    value={route?.routeurl ?? {}}
                    // onchange={(e, value) => onClick("servicecategory", value)}
                    onchange={(e, v) =>
                      handleChangelist("routeurl",v)
                    }
                    list={
                       Array.isArray(routedata?.res_appointment_approuteurl_Load?.data)
                        ? routedata?.res_appointment_approuteurl_Load?.data
                        : []
                    }
                    
                    error={error && !route?.routeurl?.label}
                    />
                </Grid>
              </Grid>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            float: "right",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            style={{
              marginRight: "34px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => handlesubmit()}
          >
            {editshow ? "update" : "save"}
          </Button>
        </div>
        {/* <div
          style={{
            width: "850px",
            height: "40px",
          }}
        ></div> */}
      </Popover>
    </>
  );
}

export default CreateRouteDetails;
