import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Col, Div, H6, Row, TextInput } from 'qdm-component-library';
import { CommonTabList, SelectBox } from '../../common';
import { AlertProps, AxiosCall, extractIdAndValue, upsertapiConnector } from '../../../utils';
import { AlertContext } from '../../../contexts';
import deleteicon from "../../../assets/icons8-trash.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    addBtn: {
        float: 'right',
        fontSize: '14px',
        fontWeight: 600,
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        marginTop: '70px',
        marginLeft: '0px',
        marginRight: '0px',
    },
    topNavbar: {},
    sideNavbar: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hovarNavBar: {
        display: 'flex',
        position: 'relative',
        top: '63px',
    },
    commanTabbar: {
        display: 'flex',
        position: 'relative',
        top: '70px',
        marginLeft: '80px',
    },
    ".MuiSvgIcon-root": {
        display: "none"
    },
}));

const ApiConnector = ({ formConnect, setFormConnect, ...props }) => {
    const classes = useStyles();
    const [connectorType, setconnectorType] = useState([]);
    const [type, setType] = useState([]);
    const [dataType, setdataType] = useState([]);
    const [source, setSource] = useState([]);
    const [authType, setauthType] = useState([]);
    const [method, setMethod] = useState([]);
    const [formError, setformError] = React.useState(false);

    const [commonList, setCommonList] = useState({});
    const alert = useContext(AlertContext);
    const [formRows, setFormRows] = useState([
        { type: '', key: '', datatype: '', source: '', value: '' }
    ]);


    React.useEffect(() => {
        getConnectorType()
        getType();
        getDataType()
        getSource()
        getAuthorisation()
        getMethod()
    }, []);


    React.useEffect(() => {
        getCommonComboData("GRANTTYPE")
        getCommonComboData("REQUESTIN")
        getCommonComboData("CODECHALLENGE")
    }, []);

    useEffect(() => {
        if (props?.editValue) {
            setFormConnect({
                connectortype: {
                    _id: props?.editValue?.[0]?.connectortype?._id,
                    label: props?.editValue?.[0]?.connectortype?.display,
                    value: props?.editValue?.[0]?.connectortype?.display
                },
                baseurl: props?.editValue?.[0]?.baseurl,
                type: {
                    _id: props?.editValue?.[0]?.headerparam?.[0]?.type?._id,
                    label: props?.editValue?.[0]?.headerparam?.[0]?.type?.display,
                    value: props?.editValue?.[0]?.headerparam?.[0]?.type?.display
                },
                key: props?.editValue?.[0]?.headerparam?.[0]?.key,
                datatype: {
                    _id: props?.editValue?.[0]?.headerparam?.[0]?.datatype?._id,
                    label: props?.editValue?.[0]?.headerparam?.[0]?.datatype?.display,
                    value: props?.editValue?.[0]?.headerparam?.[0]?.datatype?.display
                },
                source: {
                    _id: props?.editValue?.[0]?.headerparam?.[0]?.source?._id,
                    label: props?.editValue?.[0]?.headerparam?.[0]?.source?.display,
                    value: props?.editValue?.[0]?.headerparam?.[0]?.source?.display
                },
                value: props?.editValue?.[0]?.headerparam?.[0]?.value,
                authtype: {
                    _id: props?.editValue?.[0]?.authtype?._id,
                    label: props?.editValue?.[0]?.authtype?.display,
                    value: props?.editValue?.[0]?.authtype?.display,
                    code: props?.editValue?.[0]?.authtype?.code
                },
                granttype:{
                    _id: props?.editValue?.[0]?.granttype?._id,
                    label: props?.editValue?.[0]?.granttype?.display,
                    value: props?.editValue?.[0]?.granttype?.display,
                    code:props?.editValue?.[0]?.granttype?.code,
                },
                authurl:props?.editValue?.[0]?.authurl,
                callbackurl:props?.editValue?.[0]?.callbackurl,
                clientid:props?.editValue?.[0]?.callbackurl,
                clientsecret:props?.editValue?.[0]?.callbackurl,
                codeverifier:props?.editValue?.[0]?.codeverifier,
                username:props?.editValue?.[0]?.username,
                password:props?.editValue?.[0]?.password,
                scope:props?.editValue?.[0]?.scope,
                state:props?.editValue?.[0]?.state,
                tokenurl:props?.editValue?.[0]?.tokenurl,

                requestin:{
                    _id: props?.editValue?.[0]?.requestin?._id,
                    label: props?.editValue?.[0]?.requestin?.display,
                    value: props?.editValue?.[0]?.requestin?.display
                },

                codechallangemethod:{
                    _id: props?.editValue?.[0]?.codechallangemethod?._id,
                    label: props?.editValue?.[0]?.codechallangemethod?.display,
                    value: props?.editValue?.[0]?.codechallangemethod?.display
                },
                method: {
                    _id: props?.editValue?.[0]?.method?._id,
                    label: props?.editValue?.[0]?.method?.display,
                    value: props?.editValue?.[0]?.method?.display
                },
                endpoint: props?.editValue?.[0]?.endpoint,
            })
            const headerparamSet =props?.editValue[0]?.headerparam
            const setheaderparamSetarry =[]
            headerparamSet?.map((setData) =>{  
                setheaderparamSetarry.push(
                    
                        { 
                            type: {
                                _id:setData?.type?._id,
                                label:setData?.type?.display},
                            key:setData?.key,
                            datatype: {
                                _id:setData?.datatype?._id,
                                label:setData?.datatype?.display
                            },
                            source: {
                                _id:setData?.source?._id, 
                                label:setData?.source?.display}, 
                            value: setData?.value
                        }
                    
                )
            }) 
            
            setFormRows(setheaderparamSetarry)

        }

        
    }, [props?.editValue])

    const getConnectorType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["CONNECTORTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setconnectorType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["HEADERPARAMTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getDataType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["CONNECTORDATATYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setdataType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getSource = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["CONNECTORSOURCE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setSource(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAuthorisation = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["AUTHTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setauthType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getMethod = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: ["CONNECTMETHOD"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setMethod(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleChange = (key, value) => {
         if(key=="authtype"){
             setFormConnect({
                ...formConnect,
                granttype:"",
                authurl:"",
                tokenurl:"",
                callbackurl:"",
                clientid:"",
                clientsecret:"",
                scope:"",
                state:"",
                codeverifier:"",
                codechallangemethod:"",
                requestin:"",
                username:"",
                password:"",
             })
        }
        if(key=="granttype"){
            setFormConnect({
                ...formConnect,
                authurl:"",
                tokenurl:"",
                callbackurl:"",
                clientid:"",
                clientsecret:"",
                scope:"",
                state:"",
                codeverifier:"",
                codechallangemethod:"",
                requestin:"",
                username:"",
                password:"",
             })

        }
        setFormConnect({
            ...formConnect,
            [key]: value,
        })
    };

    const getCommonComboData = async (data) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    type: [data],
                    tenantid:"",
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setCommonList(prevState => ({
                    ...prevState, 
                    [data]: Resp     
                }));
                
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onSave = async () => {
        if (!formConnect?.connectortype) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill in the mandatory fields (connector Type).",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return;
        }
        if (!formConnect?.baseurl || !formConnect?.authtype) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill in the mandatory fields (Base URL and Auth Type).",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return;
        }

        // Additional validation for the base URL format (to ensure it's a valid URL, for example)
        const isValidUrl = (url) => {
            const pattern = new RegExp('^(https?:\/\/)?([a-z0-9]+[.]){1,}[a-z]{2,6}(\/[a-z0-9#]+\/?)*$', 'i');
            return pattern.test(url);
        }

        if (!isValidUrl(formConnect?.baseurl)) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please provide a valid Base URL.",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return;
        }
        if(formConnect?.authtype?.code=="AUTY005"){
            if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                if(["GRTY001", "GRTY002","GRTY004"].includes(formConnect?.granttype?.code)){
                if (!isValidUrl(formConnect?.authurl)) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please provide a valid Auth URL.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }
                if (!formConnect?.authurl) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill in the mandatory fields (Auth URL).",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }
                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004"].includes(formConnect?.granttype?.code)){
                if (!isValidUrl(formConnect?.callbackurl)) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please provide a valid Call back URL.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }
                if (!formConnect?.callbackurl) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill in the mandatory fields( Call back URL).",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }

                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                    if (!isValidUrl(formConnect?.tokenurl)) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please provide a valid Token URL.",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                    if (!formConnect?.tokenurl) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields(Token URL).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                    if (!formConnect?.clientid ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (client id).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                    if (!formConnect?.clientsecret ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (clientsecret).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                    
                    if (!formConnect?.scope ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (Scope).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                }

                if(["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code)){
                        if (!formConnect?.requestin ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (requestin).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                        }
                }
                if(["GRTY001", "GRTY002","GRTY003", "GRTY004"].includes(formConnect?.granttype?.code)){
                        if (!formConnect?.state ) {
                            setformError(true);  // Set form error state to true
                            alert?.setSnack({
                                open: true,
                                severity: AlertProps.severity.error,
                                msg: "Please fill in the mandatory fields (state).",
                                vertical: AlertProps.vertical.top,
                                horizontal: AlertProps.horizontal.right,
                                tone: true,
                            });
                            return;
                        }
                }
                if(formConnect?.granttype?.code=="GRTY002"){
                if (!formConnect?.codeverifier ) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill in the mandatory fields (codeverifier).",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }
                if (!formConnect?.codechallangemethod ) {
                    setformError(true);  // Set form error state to true
                    alert?.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill in the mandatory fields (codechallangemethod).",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.right,
                        tone: true,
                    });
                    return;
                }
                }
                if(formConnect?.granttype?.code =="GRTY004"){
                    if (!formConnect?.username ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (username).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }
                    if (!formConnect?.password ) {
                        setformError(true);  // Set form error state to true
                        alert?.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Please fill in the mandatory fields (Password).",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.right,
                            tone: true,
                        });
                        return;
                    }

                }
            }

        }
        //Edit Payload
        let payload
        if (props?.editValue?.[0]?._id) {

            const headerparam = formRows.map(item => ({
                type: item?.type?._id,
                key: item?.key,        
                datatype: item?.datatype?._id, 
                source: item?.source?._id,  
                value: item?.value        
              }));

            payload = [
                {
                    db_name: process.env.REACT_APP_METADATA_DB_NAME,
                    entity: upsertapiConnector,
                    is_metadata: false,
                    filter: {
                        _id: props?.editValue?.[0]?._id
                    },
                    doc: {
                        connectortype: formConnect.connectortype._id,
                        baseurl: formConnect.baseurl,
                        headerparam: headerparam,
                        authtype: formConnect.authtype?._id,
                        method: formConnect.method._id,
                        endpoint: formConnect.endpoint,
                        granttype:formConnect?.granttype?._id ?? "",
                        authurl:formConnect?.authurl ?? "",
                        tokenurl:formConnect?.tokenurl ?? "",
                        callbackurl:formConnect?.callbackurl ?? "",
                        clientid:formConnect?.clientid ?? "",
                        clientsecret:formConnect?.clientsecret ?? "",
                        scope:formConnect?.scope ?? "",
                        state:formConnect?.state ?? "",
                        codeverifier:formConnect?.codeverifier ?? "",
                        codechallangemethod:formConnect?.codechallangemethod?._id ?? "",
                        requestin:formConnect?.requestin?._id ?? "",
                        username:formConnect?.username ?? "",
                        password:formConnect?.password ?? "",

                    },
                },
            ];

        }

        //save payload
        else {
            const headerparam = formRows.map(row => {
                return {
                  type: row?.type?._id,         
                  key: row?.key,                
                  datatype: row?.datatype?._id,
                  source: row?.source?._id,      
                  value: row?.value             
                };
              });
            payload =
                [
                    {
                        db_name: process.env.REACT_APP_METADATA_DB_NAME,
                        entity: upsertapiConnector,
                        is_metadata: false,
                        doc: {
                            connectortype: formConnect?.connectortype?._id ?? "",
                            baseurl: formConnect?.baseurl ?? "",
                            headerparam: headerparam,                  
                            authtype: formConnect?.authtype?._id ?? "",
                            method: formConnect?.method?._id ?? "",
                            endpoint: formConnect?.endpoint ?? "",
                            granttype:formConnect?.granttype?._id ?? "",
                            authurl:formConnect?.authurl ?? "",
                            tokenurl:formConnect?.tokenurl ?? "",
                            callbackurl:formConnect?.callbackurl ?? "",
                            clientid:formConnect?.clientid ?? "",
                            clientsecret:formConnect?.clientsecret ?? "",
                            scope:formConnect?.scope ?? "",
                            state:formConnect?.state ?? "",
                            codeverifier:formConnect?.codeverifier ?? "",
                            codechallangemethod:formConnect?.codechallangemethod?._id ?? "",
                            requestin:formConnect?.requestin?._id ?? "",
                            username:formConnect?.username ?? "",
                            password:formConnect?.password ?? "",
                        }
                    }
                ]
        }
        let Resp = await AxiosCall(payload, process.env.REACT_APP_UPSERT_DOC);

        if (Resp.Code === 201) {
            props?.getApiConnectData();
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: `${props?.ApiConnector?._id ? `Saved Successfully! ID: ${props?.ApiConnector?._id}` : "Saved Successfully!"}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            setFormConnect({
                connectortype: "",
                baseurl: "",
                type: "",
                datatype: "",
                source: "",
                key: "",
                value: "",
                authtype: "",
                method: "",
                endpoint: "",
            });
        }
        props.setisFormOpen({
            isView: false,
            isEdit: false,
            isForm: false,
        })
    };

    const handleAddRow = () => {
        setFormRows([...formRows, { type: '', key: '', datatype: '', source: '', value: '' }]);
    };

    const handleChangeRow = (index, field, value) => {
        
        const updatedRows = [...formRows];
        updatedRows[index][field] = value;
        setFormRows(updatedRows);
    };

    const handleDeleteRow = (index) => {
        const updatedFormRows = [...formRows];
        updatedFormRows.splice(index, 1); // Remove the row at the given index
        setFormRows(updatedFormRows); // Assuming setFormRows updates the state
      };
      

    const onCancel = () => {
        props.setisFormOpen(false)
    }
    return (
        <div className={classes.root}>
            <Div>
                <CommonTabList
                    parent_id={'admintabpanel'}
                    title='API CONNECTOR'
                    list={[]}
                    backbtn={true}
                    backFun={() => {
                        props?.setisFormOpen({
                            ...props?.isFormOpen,
                            isForm: false,
                            isView: false,
                            isEdit: false,
                        });
                    }}
                />
            </Div>
                <Paper>
                <Div style={{ padding: "60px" }}>
                    <Div>
                        <Grid container spacing={2} direction='column'>
                            <Grid item>
                                <Div>
                                    <Row>
                                        <Col lg={6} inLineStyles={{ padding: 12 }}>
                                            <Typography
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: 0,
                                                    flex: 1,
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}
                                                variant='body1'
                                            >

                                                  
                                                Connector Type
                                                <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <SelectBox
                                                size='medium'
                                                name='connectortype'
                                                value={formConnect?.connectortype}
                                                list={extractIdAndValue("CONNECTORTYPE", connectorType)}
                                                //placeholder='API'
                                                // disabled={true}
                                                onchange={(e, v) => handleChange('connectortype', v)}
                                                disabled={props?.isFormOpen?.isView}
                                                error={ formError && !formConnect?.connectortype?.value}
                                            />
                                        </Col>
                                        <Col lg={6} inLineStyles={{ padding: 16 }}>
                                            <TextInput
                                                label={
                                                    <>
                                                        Base Url<span style={{ color: "red" }}> *</span>
                                                    </>
                                                }
                                                name='base url'
                                                value={formConnect?.baseurl}
                                                placeholder='Enter url'
                                                onChange={(e, v) => {
                                                    handleChange('baseurl', e.target.value);
                                                }}
                                                disabled={props?.isFormOpen?.isView}
                                                error={ formError && !formConnect?.baseurl}
                                                
                                            />
                                        </Col>
                                    </Row>
                                </Div>

                            </Grid>

                            <Grid item>
                                <Paper>
                                    <Div>
                                        <Div>
                                            <Row>
                                                <Col lg={10} inLineStyles={{ padding: 10 }}>
                                                    <H6 className="pi-title" style={{
                                                        fontSize: '14px',
                                                    }} >
                                                        {"Headers & Params"}
                                                    </H6>
                                                </Col>
                                                <Col lg={2}>
                                                    <Button
                                                        className={classes.addBtn}
                                                        color="primary"
                                                        onClick={handleAddRow}
                                                        hidden={props?.isFormOpen?.isView}

                                                    >
                                                        + Add
                                                    </Button>
                                                </Col>
                                            </Row>

                                            {formRows.map((row, index) => (
                                                <Row >
                                                    <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'pc_regular',
                                                                color: '#6F6F6F',
                                                            }}
                                                            variant='body1'
                                                        >
                                                            Type
                                                        </Typography>
                                                        <SelectBox
                                                            size='medium'
                                                            name='type'
                                                            value={row.type}
                                                            list={extractIdAndValue("HEADERPARAMTYPE", type)}
                                                            placeholder='Select Type'
                                                            // onchange={(e, v) => handleChange('type', v)}
                                                            onchange={(e, v) => handleChangeRow(index, 'type', v)}
                                                            disabled={props?.isFormOpen?.isView}
                                                        />
                                                    </Col>

                                                    <Col lg={2} inLineStyles={{ padding: 16 }}>
                                                        <TextInput
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'pc_regular',
                                                                color: '#6F6F6F',
                                                            }}
                                                            label='Key'
                                                            name='key'
                                                            // value={formConnect?.key}
                                                            // onChange={(e, v) => handleChange('key', e.target.value)}
                                                            value={row.key}
                                                            onChange={(e) => handleChangeRow(index, 'key', e.target.value)}
                                                        />
                                                    </Col>

                                                    <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'pc_regular',
                                                                color: '#6F6F6F',
                                                            }}
                                                            variant='body1'
                                                        >
                                                            Data Type
                                                        </Typography>
                                                        <SelectBox
                                                            size='medium'
                                                            name='datatype'
                                                            // value={formConnect?.datatype}
                                                            value={row.datatype}
                                                            list={extractIdAndValue("CONNECTORDATATYPE", dataType)}
                                                            placeholder='Select Type'
                                                            // onchange={(e, v) => handleChange('datatype', v)}
                                                            onchange={(e, v) => handleChangeRow(index, 'datatype', v)}
                                                            disabled={props?.isFormOpen?.isView}
                                                        />
                                                    </Col>

                                                    <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                        <Typography
                                                            style={{
                                                                fontSize: '14px',
                                                                fontFamily: 'pc_regular',
                                                                color: '#6F6F6F',
                                                            }}
                                                            variant='body1'
                                                        >
                                                            Source
                                                        </Typography>
                                                        <SelectBox
                                                            size='medium'
                                                            name='source'
                                                            // value={formConnect?.source}
                                                            value={row.source}
                                                            list={extractIdAndValue("CONNECTORSOURCE", source)}
                                                            placeholder='Select Type'
                                                            // onchange={(e, v) => handleChange('source', v)}
                                                            onchange={(e, v) => handleChangeRow(index, 'source', v)}
                                                            disabled={props?.isFormOpen?.isView}
                                                        />
                                                    </Col>

                                                    <Col lg={2} inLineStyles={{ padding: 16 }}>
                                                        <TextInput
                                                            label='Value'
                                                            name='value'
                                                            value={row.value}
                                                            onChange={(e) => handleChangeRow(index, 'value', e.target.value)}
                                                            disabled={props?.isFormOpen?.isView}
                                                        />
                                                    </Col>

                                                    <Col lg={2}>
                                                        <div
                                                            style={{ marginTop: "40px" }}
                                                            onClick={() => handleDeleteRow(index)}
                                                            hidden={props?.isFormOpen?.isView}
                                                        >
                                                            <img src={deleteicon} alt="Icon" />
                                                        </div>
                                                    </Col>

                                                </Row>
                                            ))}
                                        </Div>
                                    </Div>
                                </Paper>
                            </Grid>

                            <Div>
                                <Row>
                                    <Col lg={6} inLineStyles={{ padding: 12 }}>
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                margin: 0,
                                                flex: 1,
                                                fontSize: "12px",
                                                fontWeight: "600",
                                            }}
                                            variant='body1'
                                        >
                                            Authorisation Type<span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <SelectBox
                                            size='large'
                                            name='authtype'
                                            value={formConnect?.authtype}
                                            list={extractIdAndValue("AUTHTYPE", authType)}
                                            placeholder='Select Type'
                                            onchange={(e, v) => {
                                                handleChange('authtype', v);
                                            }}
                                            disabled={props?.isFormOpen?.isView}
                                        />
                                    </Col>
                                </Row>
                            </Div>


                            <Grid item>
                                <Paper>
                                       {formConnect?.authtype?.code =="AUTY005" &&
                                       <Grid container xs={12} spacing={2}
                                       sx={{
                                        backgroundColor: '#ffffff', // White background
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', // Black shadow with some opacity
                                        borderRadius: '8px', // Optional: rounded corners
                                        padding: '20px', // Optional: padding inside the container
                                        maxWidth: 'lg', // Optional: maximum width of the container
                                      }}
                                       >
                                       <Grid item xs={6} size="grow">
                                               
                                                    <Typography
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            margin: 0,
                                                            flex: 1,
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                        }}
                                                        variant='body1'
                                                    >
                                                        Grant Type <span style={{ color: 'red' }}> *</span>
                                                    </Typography>         
                                                    <SelectBox
                                                        size='large'
                                                        name='granttype'
                                                        value={formConnect?.granttype}
                                                        list={extractIdAndValue("GRANTTYPE"
                                                            , commonList?.GRANTTYPE)}
                                                        placeholder='Select Type'
                                                        onchange={(e, v) => {
                                                            handleChange('granttype', v);
                                                        }}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                        </Grid>
                                    {/* Auth URL */}
                                    { ["GRTY002", "GRTY004","GRTY001",].includes(formConnect?.granttype?.code)  && <Grid item xs={6}> 

                                                    <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                            width: "100%",  
                                                        }}
                                                        label={
                                                        <>
                                                        Auth URL
                                                        <span style={{ color: 'red' }}> *</span>
                                                        </>
                                                        } 
                                                        name='authurl'
                                                        value={formConnect?.authurl}
                                                        onChange={(e, v) => {
                                                            handleChange('authurl', e.target.value);
                                                        }}
                                                        error={ formError && !formConnect?.authurl}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                        </Grid> }

                                        {/* Token URL */}
                                      {["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}>
                                         <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        label={
                                                        <>
                                                        Token URL
                                                        <span style={{ color: 'red' }}> *</span>
                                                        </>
                                                        } 
                                                        name='tokenurl'
                                                        value={formConnect?.tokenurl}
                                                        onChange={(e, v) => {
                                                            handleChange('tokenurl', e.target.value);
                                                        }}
                                                        error={ formError && !formConnect?.tokenurl}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                         </Grid> }
                                         {/* CallBack URL */}
                                       {["GRTY001", "GRTY002","GRTY003", "GRTY004"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}>

                                                
                                               
                                                    <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        label={
                                                        <>
                                                        CallBack URL
                                                        <span style={{ color: 'red' }}> *</span>
                                                        </>
                                                        } 
                                                        name='endpoint'
                                                        value={formConnect?.callbackurl}
                                                        onChange={(e, v) => {
                                                            handleChange('callbackurl', e.target.value);
                                                        }}
                                                        error={ formError && !formConnect?.callbackurl}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                         </Grid>}

                                         {/* Client ID */}
                                        {["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 

                                            
                                                    <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        label={
                                                        <>
                                                        Client ID
                                                        <span style={{ color: 'red' }}> *</span>
                                                        </>
                                                        } 
                                                        name='clientid'
                                                        value={formConnect?.clientid}
                                                        onChange={(e, v) => {
                                                            handleChange('clientid', e.target.value);
                                                        }}
                                                        error={ formError && !formConnect?.clientid}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                                

                                        </Grid>}
                                        {/* Client Secret */}
                                        {["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                Client Secret
                                                <span style={{ color: 'red' }}> *</span>
                                                </>
                                                } 
                                                name='endpoint'
                                                value={formConnect?.clientsecret}
                                                onChange={(e, v) => {
                                                    handleChange('clientsecret', e.target.value);
                                                }}
                                                error={ formError && !formConnect?.clientsecret}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}
                                        {/* Scope */}
                                        {["GRTY001", "GRTY002","GRTY003", "GRTY004","GRTY005"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                Scope
                                                <span style={{ color: 'red' }}> *</span>
                                                </>
                                                } 
                                                name='scope'
                                                value={formConnect?.scope}
                                                onChange={(e, v) => {
                                                    handleChange('scope', e.target.value);
                                                }}
                                                error={ formError && !formConnect?.scope}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}

                                        {/* State */}
                                        {["GRTY001", "GRTY002","GRTY003", "GRTY004"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                        
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                State
                                                </>
                                                } 
                                                name='endpoint'
                                                value={formConnect?.state}
                                                onChange={(e, v) => {
                                                    handleChange('state', e.target.value);
                                                }}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}

                                        {/* Code Verifier */}
                                        {["GRTY002"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                Code Verifier
                                                <span style={{ color: 'red' }}> *</span>
                                                </>
                                                } 
                                                name='codeverifier'
                                                value={formConnect?.codeverifier}
                                                onChange={(e, v) => {
                                                    handleChange('codeverifier', e.target.value);
                                                }}
                                                error={ formError && !formConnect?.codeverifier}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}

                                        {/* Code Challenge Method */}
                                        {["GRTY002"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                        
                                            <Typography
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: 0,
                                                    flex: 1,
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}
                                                variant='body1'
                                            >
                                                Code Challenge Method<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <SelectBox
                                                size='large'
                                                name='codechallangemethod'
                                                value={formConnect?.codechallangemethod}
                                                list={extractIdAndValue("CODECHALLENGE",commonList?.CODECHALLENGE )}
                                                placeholder='Select Type'
                                                onchange={(e, v) => {
                                                    handleChange('codechallangemethod', v);
                                                }}
                                                error={ formError && !formConnect?.codechallangemethod?.value}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}
                                        {/* Request In */}
                                        {["GRTY001", "GRTY002","GRTY003", "GRTY004"].includes(formConnect?.granttype?.code) &&<Grid item xs={6}> 
                                            <Typography
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: 0,
                                                    flex: 1,
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}
                                                variant='body1'
                                            >
                                                    Request In <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <SelectBox
                                                size='large'
                                                name='method'
                                                value={formConnect?.requestin}
                                                list={extractIdAndValue("REQUESTIN", 
                                                    commonList?.REQUESTIN
                                                )}
                                                placeholder='Select Type'
                                                onchange={(e, v) => {
                                                    handleChange('requestin', v);
                                                }}
                                                error={ formError && !formConnect?.requestin?.value}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>}
                                        {/* UserName and password */}
                                        {formConnect?.granttype?.code =="GRTY004" && 
                                        <>
                                        <Grid item xs={6}> 
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                UserName
                                                <span style={{ color: 'red' }}> *</span>
                                                </>
                                                } 
                                                name='username'
                                                value={formConnect?.username}
                                                onChange={(e, v) => {
                                                    handleChange('username', e.target.value);
                                                }}
                                                error={ formError && !formConnect?.username}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>
                                        <Grid item xs={6}> 
                                            <TextInput
                                                style={{
                                                    fontSize: '14px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                label={
                                                <>
                                                Password
                                                <span style={{ color: 'red' }}> *</span>
                                                </>
                                                } 
                                                name='password'
                                                value={formConnect?.password}
                                                onChange={(e, v) => {
                                                    handleChange('password', e.target.value);
                                                }}
                                                error={ formError && !formConnect?.password}
                                                disabled={props?.isFormOpen?.isView}
                                            />
                                        </Grid>
                                        </>
                                        }
                                        </Grid>}
                                         
                                </Paper>
                            </Grid>

                        

                            {/* <Div>
                                <Row>
                                    <Col lg={10} inLineStyles={{ padding: 12 }}>
                                        <Typography
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                margin: 0,
                                                flex: 1,
                                                fontSize: "12px",
                                                fontWeight: "600",
                                            }}
                                            variant='body1'
                                        >
                                            Api Reference <span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <SelectBox
                                            size='large'
                                            name='authtype'
                                            value={formConnect?.authtype}
                                            list={extractIdAndValue("AUTHTYPE", authType)}
                                            placeholder='Select Type'
                                            onchange={(e, v) => {
                                                handleChange('authtype', v);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Div> */}

                            <Grid item>
                                <Paper>
                                    <Div>
                                        <Div>
                                            <Row >
                                                <Col lg={4} inLineStyles={{ padding: 12 }}>
                                                    <Typography
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            margin: 0,
                                                            flex: 1,
                                                            fontSize: "12px",
                                                            fontWeight: "600",
                                                        }}
                                                        variant='body1'
                                                    >
                                                        Method (Check Service)
                                                    </Typography>
                                                    <SelectBox
                                                        size='large'
                                                        name='method'
                                                        value={formConnect?.method}
                                                        list={extractIdAndValue("CONNECTMETHOD", method)}
                                                        placeholder='Select Type'
                                                        onchange={(e, v) => {
                                                            handleChange('method', v);
                                                        }}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                                </Col>

                                                <Col lg={4} inLineStyles={{ padding: 16 }}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: 0,
                                                    flex: 1,
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                }}>
                                                    <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        label='End Point (Check Service)'
                                                        name='endpoint'
                                                        value={formConnect?.endpoint}
                                                        onChange={(e, v) => {
                                                            handleChange('endpoint', e.target.value);
                                                        }}
                                                        disabled={props?.isFormOpen?.isView}
                                                    />
                                                </Col>

                                            </Row>

                                        </Div>
                                    </Div>
                                </Paper>
                            </Grid>


                            <Grid id={'agerange-form-rangedetails-grid'} style={{ padding: '20px' }}>
                                <Button
                                    id={'agerange_form_rangedetails_Save_button'}
                                    style={{ float: 'right' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={onSave}
                                    hidden={props?.isFormOpen?.isView}
                                >
                                    {props?.editValue?.length ? "Update" : "Save"}
                                </Button>
                                <Button
                                    id={'agerange_form_rangedetails_Cancel_button'}
                                    style={{ float: 'right', marginRight: '20px' }}
                                    variant='outlined'
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Div>
                </Div>

                
        </Paper>

        </div>
    )
}

export default ApiConnector



