import React from "react";
import { Row, Col, TextInput } from "qdm-component-library";
import {
	FormLabel,
	makeStyles,
	Grid,
	Button,
	Paper,
	IconButton,
	Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withAllContexts } from "../../../HOCs";
import { valitationFunc } from "../../../utils";
import CustomInputDatePicker from "../../../components/common/datePicker/inputDatePicker";
import moment from "moment";

const useStyles = makeStyles(() => ({
	main: {
		backgroundColor: "white",
		width: "90vw",
		borderRadius: 8,
		padding: 14,
	},
	paddingLeft0: {
		paddingLeft: 0,
		marginBottom: "1%",
	},
	dot: {
		height: "7px",
		width: "7px",
		backgroundColor: "#bbb",
		borderRadius: "50%",
		display: "inline-block",
		marginTop: "5px",
		marginRight: "7px",
		marginLeft: "7px",
	},
	req_red: {
		color: "red",
	},
	labelStyleNew: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
		marginBottom: 10
	},
}));
const styles = {
	labelStyle: {
		color: "#6F6F6F",
		fontSize: "12px",
		marginBottom: "6px",
		fontFamily: `pc_regular !important`,
	},
	borderStyle: {
		borderRadius: 8,
		borderColor: "#E0E0E0",
	},
	errorBorderStyle: {
		borderRadius: 8,
		border: "1px solid red",
	},
};
const AddSettings = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);
	const { parent_id } = props;
	const [hide, setshide] = React.useState(false);
	const [state, setState] = React.useState({
		tenant:null,
		currency:null,
		startdate: "",
		enddate: "",
		datetime: "",
		timezone:null,
		// designation: {},
		point: [
			{
				mode: {},
				code: {},
				number: "",
				tenant: {},
				priority: {},
			},
		],
		error:{}
	});
	const [contactError,setContactErr]=React.useState([])
	React.useLayoutEffect(() => {
		const getdata = async () => {
			// await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE());
			await dispatch(actions.ORGANIZATION_GET_CONTACTTENANT());
			// await dispatch(actions.ORGANIZATION_GET_CONTACT_SUFFIX());
			// await dispatch(actions.ORGANIZATION_GET_CONTACT_PREFIX());
			await dispatch(actions.ORGANIZATION_GET_CURRENCY());
			await dispatch(actions.ORGANIZATION_GET_CONTACT_DESI());
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILPRIORITY());
			await dispatch(actions.ORGANIZATION_GET_CONNTACTDETAILCODE());
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILUSE());
			await dispatch(actions.ORGANIZATION_GET_CONTACTDETAILMODE());
			await dispatch(actions.ORGANIZATION_GET_TIMEZONE());
		};
		getdata();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (props?.editData?.length > 0) {
			setState({
				...state,
				tenant: props?.editData[0]?.tenant,
				currency: props?.editData[0]?.currency,
				startdate: props?.editData[0]?.startdate,
				enddate: props?.editData[0]?.enddate,
				datetime: props?.editData[0]?.datetime,
				timezone: props?.editData[0]?.timezone,
				// designation: props?.editData[0]?.designation,
				point: props?.editData[0]?.point,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.editData]);
	const { labelStyle } = styles;

	const addNew = () => {
		state?.point?.push({
      mode: {},
      code: {},
      number: "",
      use: {},
      priority: {},
    });
    setContactErr([...contactError]);
	};
	const handleClick = () => {
		setshide(true);
	};
	const handleClose = () => {
		setState({
			tenant:null,
			currency:null,
			startdate: "",
			enddate: "",
			datetime: "",
			timezone:null,
			// designation: {},
			point: [
				{
					mode: {},
					code: {},
					number: "",
					use: {},
					priority: {},
				},
			],
		});
		setshide(false);
	};
	const Savefun = async(e) => {
    let errorList = [
      "tenant",
      "currency",
    ];
    let { error, errorState } = await valitationFunc(state, errorList);

    let contactErr = false;

    if (!error && !contactErr) {
      props?.onChangeAll(e, state, "ContactSettingsDetails");
      handleClose();
    } else {
      let customMsg =
		  "Please fill all the mandatory fields";
      props.alert.setSnack("mandatory", customMsg);
      setState({
        ...state,
        error: errorState,
      });
    }
  };
	const generateFun1 = (e, val, types) => {
		 
			if (state?.error?.[types]){
				state.error[types] = false;
			} 
			setState({ ...state, [types]: val });
		
	};
	
	const generateFun = (e, types, val) => {
		state[types] = val;
		setState({ ...state });
	};
	
	return (
		<Grid
			id={`${parent_id}-parent-grid`}
			className={classes.main} container direction="row">
			<Grid
				id={`${parent_id}-parent-sub-grid`}
				container item xs={12}>
				<Grid
					id={`${parent_id}-parent-sublevel-grid`}
					item xs={12}>
					<Grid
						id={`${parent_id}-parent-container-grid`}
						container>
						<Grid
							id={`${parent_id}-CONTACT-SETTINGS-DETAILS-grid`}
							item xs={8} style={{ textAlign: "left" }}>
							<p
								id={`${parent_id}-CONTACT-SETTINGS-DETAILS-title-p`}
								style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
								SETTINGS{" "}
							</p>
						</Grid>
						<Grid
							id={`${parent_id}-button-grid`}
							item xs={4}>
							<Grid
								id={`${parent_id}-button-sub-grid`}
								container
								direction="row"
								justify="flex-end"
								alignItems="center"
							>
								{props?.ContactSettingsDetailsData?.length!=1 && (								
								<Button
									id={`${parent_id}-Add-New-Address-button`}
									className={classes.newRes}
									color="primary"
									onClick={handleClick}
								>									
									+ Add New Settings									
								</Button>
								)}
							</Grid>
							
						</Grid>
						{props?.ContactSettingsDetailsData?.map((val, i) => {
							return (
								<Grid
									id={`${parent_id}-details-parent-grid` + i}
									container
									style={{
										border: "1px solid #E0E0E0",
										padding: 12,
										marginBottom: 8,
										backgroundColor: "#F9F9F9",
										position: "relative",
									}}
									direction="row"
									alignItems="left"
								>
									<Grid
										id={`${parent_id}-details-parent-sub-grid` + i}
										item xs={6}>
										<Grid
											id={`${parent_id}-details-parent-grid`}
											container
											direction="row"
											justify="flex-end"
											alignItems="left"
										>
											<Grid
												id={`${parent_id}-details-styled-grid` + i}
												item
												xs={1}
												style={{
													backgroundColor: "#E0E0E0",
													position: "absolute",
													left: 0,
													padding: "6px 12px",
													borderTopRightRadius: 9,
													borderBottomRightRadius: 9,
												}}
											>
												{i + 1}
											</Grid>
													<Grid
														id={`${parent_id}-details-grid` + i}
														item
														xs={12}
														style={{ marginLeft: "6%", textAlign: "left" }}
													>
														<div
														id={`${parent_id}-details-mode-div` + i}
														>Details</div>
														<>																						
														{val?.tenant?.title}  {"-"}																							
														{val?.currency?.title} {"-"}																											
														{val?.timezone?.title} </>											            
														
												</Grid>										
										</Grid>
									</Grid>
									<Grid
										id={`${parent_id}-IconButton-grid`}
										item xs={6}>
										<Grid
											id={`${parent_id}-EditIcon-grid`}
											container
											direction="row"
											justifyContent="flex-end"
											alignItems="center"
										>
											<IconButton
												id={`${parent_id}-EditIcon-IconButton`}
												aria-label="EditIcon"
												className={classes.margin}
												size="small"
												style={{ marginTop: "1%" }}
											>
												<EditIcon
													id={`${parent_id}-EditIcon`}
													onClick={(e) => {
														handleClick();
														props.editFun("ContactSettingsDetails", i);
													}}
													style={{
														color: "#0071F2",
														fontSize: 18,
													}}
												/>
											</IconButton>
											<IconButton
												id={`${parent_id}-IconButton`}
												aria-label="DeleteIcon"
												className={classes.margin}
												size="small"
												style={{ marginTop: "1%" }}
												onClick={() =>
													props.deleteFun("ContactSettingsDetails", i)
												}
											>
												<DeleteIcon
													id={`${parent_id}-DeleteIcon`}
													style={{ color: "#FF4D4A", fontSize: 18 }}
												/>
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
				{hide ? 
				(
					<Grid
						id={`${parent_id}-parent-container`}
						container direction="column">
						<Grid
							id={`${parent_id}-parent-grid`}
							container direction="column">
							{/* <Row
								id={`${parent_id}-title-row`}
								inLineStyles={{ paddingBottom: "2%", paddingTop: "2%" }}>
								ADD NEW SETTINGS
							</Row> */}
							<Paper
								id={`${parent_id}-CONTACT-SETTINGS-paper`}
								style={{ padding: 20 }}>
								{/* <Row
									id={`${parent_id}-CONTACT-SETTINGS-title-row`}
									inLineStyles={{ paddingBottom: "2%" }}>SETTINGS</Row> */}
								<Row
									id={`${parent_id}-CONTACT-SETTINGS-row`}
								>
									
									<Col
										id={`${parent_id}-CONTACT-SETTINGS-tenant-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-CONTACT-SETTINGS-tenant-FormLabel`}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											Tenant <span
												id={`${parent_id}-CONTACT-SETTINGS-tenant-star-span`}
												className={classes.req_red}>*</span>
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-CONTACT-SETTINGS-tenant-autocomplete`}
											//getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-CONTACT-SETTINGS-tenant-textField`}
													{...params} variant="outlined"
													error={state?.error?.tenant}
													 />
											)}
											//getOptionLabel={(option) => `${option.code} - ${option.title}`}
											getOptionLabel={(option) => option.title}
											options={
												organizationSlice?.orgSettingsTenant?.data
													? organizationSlice?.orgSettingsTenant?.data
													: []
											}
											onChange={(e, val) => generateFun1(e, val, "tenant")}
											// handleChange={(e, val) =>
											// 	generateFun(e, val, "tenant")
											// }
											value={state?.tenant}
										/>
									</Col>
									
									<Col
										id={`${parent_id}-CONTACT-SETTINGS-Currency-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-CONTACT-SETTINGS-Currency-FormLabel`}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											Currency <span
												id={`${parent_id}-CONTACT-SETTINGS-Currency-star-span`}
												className={classes.req_red}>*</span>
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-CONTACT-SETTINGS-Currency-autocomplete`}
											// getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-CONTACT-SETTINGS-Currency-textField`}
													{...params} variant="outlined"
													error={state?.error?.currency}
													/>
											)}
											//getOptionLabel={(option) => `${option.code} - ${option.title}`}
											getOptionLabel={(option) => option.title}
											options={
												organizationSlice?.orgSettingsCurrency?.data
													? organizationSlice?.orgSettingsCurrency?.data
													: []
											}
											onChange={(e, val) => generateFun1(e, val, "currency")}
											// handleChange={(val) =>
											// 	generateFun(val, "currency", val)
											// }
											 value={state?.currency}
										/>
									</Col>

								<Col
								id={`${parent_id}-startdate-Col`}
								md={2}
								lg={2}
								sm={2}
								xs={12}
								className={classes.paddingLeft0}
							>
								<Typography
									style={{
										fontFamily: "poppin",
										color: "#6F6F6F",
										fontSize: "12px",
										paddingBottom: "8px",
									}}
								>
									{"Date Format"}
								</Typography>
								{/* <CustomInputDatePicker
									placeholderText="DD-MM-YYYY"
									selectedDate={
										state?.startdate ? new Date(state?.startdate) : null
									}
									defaultValue={moment(new Date()).format("DD-MM-YYYY")}
									error={state?.error?.startdate}
									// onChange={(e, val) => generateFun(e, val, "startdate")}
									// value={state?.startdate}
									handleChange={(val) =>
										generateFun(val, "startdate", val)
									}
									inputField={
										<TextField
											id={`Start-Date`}
											fullWidth
											variant="outlined"
											value={
												state?.startdate
												? moment(new Date(state?.startdate)).format("DD-MM-YYYY"): null
											}
											inputProps={{
												style: {
													padding: "10.5px 14px",
													fontFamily: "poppin",
													fontSize: "12px",
													},
											}}
										/>
									}
								/>*/}

								<TextField
                    			id={`Start-Date`}
                    variant="outlined"
					fullWidth
                    type="date"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    error={state?.error?.startdate}
                    value={
						state?.startdate
						? moment(state?.startdate).format(
						"YYYY-MM-DD"
						)
						: null
					}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    handleChange={(val) =>
						generateFun(val, "startdate", val)
					}
					inputProps={{
						style: {
							padding: "10.5px 14px",
							fontFamily: "poppin",
							fontSize: "12px",
							},
								}}
                  				/>
								</Col>
									
								<Col
								id={`${parent_id}-enddate-Col`}
								md={2}
								lg={2}
								sm={2}
								xs={12}
								className={classes.paddingLeft0}
							>
								<Typography
									style={{
										fontFamily: "poppin",
										color: "#6F6F6F",
										fontSize: "12px",
										paddingBottom: "8px",
									}}
								>
									{"Time Format"}
								</Typography>
								{/* <CustomInputDatePicker
									placeholderText="HH:mm"
									showTimeSelect
                        			showTimeSelectOnly
                        			hideTimeIcon={true}
                        			timeFormat="HH:mm"
                        			timeIntervals={1}
                        			selectedDate={null}
									// defaultValue={moment(new Date()).format("HH:mm")}
									defaultValue={state?.enddate || moment().format("HH:mm")}
									handleChange={(val) =>
										generateFun(val, "enddate", val)
									}
									InputLabelProps={{
										shrink: true,
									  }}
									  error={state?.error?.enddate}
									inputField={
										<TextField
											id={`End-Date`}
											fullWidth
											type="time"
											variant="outlined"
											//value={state?.enddate || moment().format("HH:mm")} 
											value={
												state?.enddate
												? moment(state?.enddate).format(
												"HH:mm"
												)
												: null
											}
											// value={time}
											inputProps={{
												style: {
													padding: "10.5px 14px",
													fontFamily: "poppin",
													fontSize: "12px",
													},
											}}
										/>
									}
								/> */}

							<TextField
                    			id={`End-Date`}
                    variant="outlined"
					fullWidth
                    type="time"
                    defaultValue={moment(new Date()).format("HH:mm")}
                    error={state?.error?.enddate}
                    value={
						state?.enddate
						? moment(state?.enddate).format(
						"HH:mm"
						)
						: null
					}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    handleChange={(val) =>
						generateFun(val, "enddate", val)
					}
					inputProps={{
						style: {
							padding: "10.5px 14px",
							fontFamily: "poppin",
							fontSize: "12px",
							},
								}}
                  				/>
								</Col>
									
									<Col
								id={`${parent_id}-datetime-Col`}
								md={2}
								lg={2}
								sm={2}
								xs={12}
								className={classes.paddingLeft0}
							>
								<Typography
									style={{
										fontFamily: "poppin",
										color: "#6F6F6F",
										fontSize: "12px",
										paddingBottom: "8px",
									}}
								>
									{"Date & Time Format"}
								</Typography>
								
								<TextField
                    			id={`Date-Time`}
                    variant="outlined"
					fullWidth
                    type="datetime-local"
                    defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    error={state?.error?.datetime}
                    value={
						state?.datetime
						? moment(state?.datetime).format(
						"YYYY-MM-DDTHH:mm"
						)
						: null
					}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    handleChange={(val) =>
						generateFun(val, "datetime", val)
					}
					inputProps={{
						style: {
							padding: "10.5px 14px",
							fontFamily: "poppin",
							fontSize: "12px",
							},
								}}
                  				/>
									</Col>
									
									<Col
										id={`${parent_id}-CONTACT-SETTINGS-Timezone-col`}
										md={2}
										lg={2}
										sm={2}
										xs={12}
										className={classes.paddingLeft0}
									>
										<FormLabel
											id={`${parent_id}-CONTACT-SETTINGS-Timezone-FormLabel`}
											component="legend"
											classes={{root:classes.labelStyleNew}}
										>
											Timezone
										</FormLabel>
										<Autocomplete
											id={`${parent_id}-CONTACT-SETTINGS-Timezone-autocomplete`}
											// getOptionLabel={(option) => option.title}
											style={{ width: "auto", marginTop: "1%" }}
											size={"small"}
											renderInput={(params) => (
												<TextField
													id={`${parent_id}-CONTACT-SETTINGS-Timezone-textField`}
													{...params} variant="outlined"
													// error={state?.error?.timezone}
													 />
											)}
											//getOptionLabel={(option) => `${option.code} - ${option.title}`}
											getOptionLabel={(option) => option.title}
											options={
												organizationSlice?.orgSettingsTimezone?.data
													? organizationSlice?.orgSettingsTimezone?.data
													: []
											}
											onChange={(e, val) => generateFun1(e, val, "timezone")}
											 value={state?.timezone}
										/>
									</Col>
									
								</Row>
							</Paper>
							<Paper
								// id={`${parent_id}-CONTACT-SETTINGS-DETAILS-Paper`}
								// style={{ marginTop: "2%", padding: 20, marginBottom: "2%" }}
							>
								<Grid
									// id={`${parent_id}-CONTACT-SETTINGS-DETAILS-grid`}
									// item xs={12}
									>
									<Grid
										// id={`${parent_id}-CONTACT-SETTINGS-grid`}
										// container
										>
										<Grid
											// id={`${parent_id}-CONTACT-SETTINGS-sub-grid`}
											// item xs={8} style={{ textAlign: "left" }}
											>
											<p
												// id={`${parent_id}-CONTACT-SETTINGS-DETAILS-title-p`}
												// style={{
												// 	fontFamily: "poppinsemibold",
												// 	marginTop: "0px",
												// }}
											>
												{/* CONTACT SETTINGS DETAILS{" "} */}
											</p>
										</Grid>
										<Grid
											// id={`${parent_id}-CONTACT-SETTINGS-Add-New-Settings-grid`}
											//item xs={4}
											>
											<Grid
												// id={`${parent_id}-CONTACT-SETTINGS-Add-New-Settings-sub-grid`}
												// container
												// direction="row"
												// justify="flex-end"
												// alignItems="center"
											>
												{/* <Button
													id={`${parent_id}-CONTACT-SETTINGS-Add-New-Settings-button`}
													className={classes.newRes}
													color="primary"
													onClick={addNew}
													//id={"general_appointment_card_Id"}
												>
													+ Add New Settings
												</Button> */}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								{/* {state.point.map((val, i) => {
								
								})} */}
							</Paper>
						</Grid>
						<Row
							id={`${parent_id}-buttons-row`}
							style={{ display: "flex", justifyContent: "center" }}>
							<Button
								id={`${parent_id}-Cancel-button`}
								variant={"outlined"} onClick={handleClose}>
								Cancel
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Button
								id={`${parent_id}-Add-button`}
								variant={"contained"} color="primary" onClick={Savefun}>
								Add
							</Button>
						</Row>
					</Grid>
				) 
				: null } 
			</Grid>
		</Grid >
	);
};
export default withAllContexts(AddSettings);
